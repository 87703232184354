import type { MessageFunction } from 'vue-i18n';
import homepage from './pages/homepage/index';
import tech from './pages/tech/index';
import statistics from './pages/statistics/index';
import Info from './pages/info/index';
import system from './pages/system/index';
// 匈牙利
export default {
    license: {
        powered_by: 'SMIL által támogatva',
    },
    auth: {
        login: 'Bejelentkezés',
        username: 'Felhasználónév',
        username_placeholder: 'Adja meg a felhasználónevet',
        password: 'Jelszó',
        password_placeholder: 'Adja meg a jelszót',
        code: 'Ellenőrző kód',
        code_placeholder: 'Adja meg az ellenőrző kódot',
        logout: 'Kijelentkezés',
        logout_success: 'Sikeres kijelentkezés',
        day: 'Nap',
        password_expiration: 'A jelszó lejárt, kérjük, változtassa meg',
        expire: 'A jelszó lejáratáig hátralévő idő',
        conseils:
            'A jelszónak tartalmaznia kell nagybetűket, kisbetűket, számokat és speciális karaktereket ({msg}). A jelszó legyen 8–16 karakter hosszú.',
        brand_Selection: 'Márkaválasztás',
        unread_notices: 'Olvasatlan értesítések',
        new_cases: 'Új esetek',
        unanswered_cases: 'Megválaszolatlan esetek',
        overdue_cases: 'Késésben lévő esetek',
    },
    button: {
        query: 'Keresés',
        editing_position: 'Pozíció szerkesztése',
        show_theme_editor: 'Témaszerkesztő megjelenítése/elrejtése',
        change_password: 'Jelszó módosítása',
        old_password: 'Régi jelszó',
        new_password: 'Új jelszó',
        confirm_password: 'Jelszó megerősítése',
        button: 'Gomb',
        about: 'Névjegy',
        back: 'Vissza',
        go: 'Megerősítés',
        home: 'Kezdőlap',
        confirm: 'Megerősítés',
        modify: 'Módosítás',
        edit: 'Szerkesztés',
        new: 'Új hozzáadása',
        cancel: 'Mégse',
        close: 'Bezárás',
        search: 'Keresés',
        reset: 'Visszaállítás',
        delete: 'Törlés',
        add: 'Hozzáadás',
        collapse: 'Összecsukás',
        expand: 'Kibontás',
        download_template: 'Sablon letöltése',
        batch_import: 'Tömeges importálás',
        import: 'Importálás',
        export: 'Exportálás',
        export_fail: 'Importálás sikertelen',
        detailed: 'Részletek',
        details: 'Részletek',
        download: 'Letöltés',
        toView: 'Megtekintés',
        toViews: 'Kép megtekintése',
        uploadAttachments: 'Feltöltés',
        selectFile: 'Fájl kiválasztása',
        fillingInstructions: 'Kitöltési útmutató',
        release: 'Kiadás',
        release_recall: 'Kiadás visszavonása',
        save: 'Mentés',
        send: 'Küldés',
        tips: 'Tippek',
        send_back: 'Visszatérés',
        examine: 'Vizsgálat',
        void: 'Érvénytelen',
        more: 'Továbbiak',
        refresh: 'Frissítés',
        selects: 'Válasszon',
        serial_number: 'Sorozatszám',
        upload_progress: 'Feltöltési folyamat',
        invalid: 'Érvénytelen',
        daily_record: 'Napi rekord',
        operation_type: 'Művelet típusa',
        field_name: 'Mező neve',
        edit_role: 'Szerep módosítása',
        user_role: 'Felhasználói szerep',
        assignment_role: 'Szerep',
        post: 'Közzététel',
        sending: 'Küldés',
        reset_password: 'Jelszó visszaállítása',
        display_or_not: 'Megjelenjen-e',
        document: 'Dokumentum',
        specificTemplates: 'Specifikus sablonok',
        transferOrder: 'Rendelés továbbítása',
        forwarding: 'Továbbítás',
        one_click_notification: 'Egykattintásos értesítés',
        english_export: 'English Export',
        oneclick_download: 'Egyszeri kattintásos letöltés',
        passwordmsga:
            'Tartalmaznia kell nagybetűket, kisbetűket, számokat és speciális karaktereket （@\\$!_~\\&*）',
        passwordmsgb: 'A jelszó hossza 8 és 16 karakter között kell, hogy legyen',
        passwordmsgc: 'Nem lehet azonos az előző hat jelszóval',
        export_file_list: 'Fájl lista exportálása',
    },
    radio: {
        enabled: 'Engedélyezve',
        disabled: 'Kikapcsolva',
        catalogue: 'Katalógus',
        individual: 'Egyéni',
        all: 'Összes',
        yes: 'Igen',
        no: 'Nem',
    },
    dropdown: {
        close_others: 'Többi bezárása',
        close_right: 'Jobb oldali bezárása',
        close_left: 'Bal oldali bezárása',
    },
    table: {
        action: 'Művelet',
        explain: 'Leírás',
        fileName: 'Csatolmány neve',
        size: 'Méret (MB)',
        uploader: 'Feltöltő',
        uploadTime: 'Feltöltési idő',
        system: 'Rendszer',
        caption: 'Képfelirat',
        categorization: 'Kategorizálás',
        link: 'Hivatkozás',
        forwarding_time: 'Továbbítás ideje',
        forwarder: 'Továbbító',
        send_to: 'Címzett',
        mode: 'Mód',
    },

    pagination: {
        pages: ({ named }: Parameters<MessageFunction>[0]) => `${named('number')}/Oldal`,
        total: ({ named }: Parameters<MessageFunction>[0]) => `Összesen ${named('number')} adat`,
        vinCount: ({ named }: Parameters<MessageFunction>[0]) => `Összesen ${named('number')} autó`,
    },
    confirm: {
        confirm_deletion: 'Biztosan törli?',
        confirm_void: 'Biztosan érvényteleníti?',
        confirm_release: 'Biztosan kiadja?',
        ack: 'Megerősítés',
        cancel: 'Felfüggesztés',
        non_records: 'Válasszon legalább egy rekordot',
    },
    request: {
        failed_placeholder: 'Sikertelen kérelem, backend hiba',
        success_placeholder: 'Sikeres kérelem',
        auth: {
            login_success: 'Sikeres bejelentkezés',
        },
        error: {
            '404': 'A kért URL nem található',
            '403': 'Engedély megtagadva',
        },
    },
    page: {
        global: {
            page_search: 'Oldal keresése',
        },
        error: {
            not_found: 'Nem található',
            not_found_des: 'Itt nincsen semmi',
            auth_failed: 'Sikertelen hitelesítés',
            auth_failed_des: 'Ez az oldal nem elérhető',
            file_format_error: 'Hibás fájlformátum!',
            preview_failed: 'Sikertelen előnézet! Hibás fájl!',
        },
        homepage: {
            homepage: homepage.homepage.huHU,
        },
        system: {
            menu: system.menu.huHU,
            dict: system.dict.huHU,
            dept: system.dept.huHU,
            role: system.role.huHU,
            user: system.user.huHU,
        },
        info: {
            announcement: Info.announcement.announcement.huHU,
            document: Info.document.document.huHU,
        },
        tech: {
            quality: {
                problemTracking: tech.quality.problemTracking.huHU,
                recallActivity: tech.quality.recallActivity.huHU,
                abnormalClaimTracking: tech.quality.abnormalClaimTracking.huHU,
            },
            management: {
                inquiryOd: tech.management.inquiry.huHU,
                permission: tech.management.permission.huHU,
                onSiteSupportHq: tech.management.onSiteSupport.huHU,
                progHistoryHq: tech.management.progHistory.huHU,
                salesRecordHq: tech.management.salesRecord.huHU,
                vehicleInfoHq: tech.management.vehicleInfo.huHU,
                claimHq: tech.management.claim.huHU,
                filterHq: tech.management.filter.huHU,
                newCarlssue: tech.management.newCarIssue.huHU,
                newCarDefinition: tech.management.newCarDefinition.huHU,
                vehicleProductionInfo: tech.management.vehicleProductionInfo.huHU,
                programmingHistoryInformationQuery:
                    tech.management.programmingHistoryInformationQuery.huHU,
                ecuControllerInformationQuery: tech.management.ecuControllerInformationQuery.huHU,
            },
        },
        components: {
            businessComponents: tech.management.businessComponents.huHU,
        },
        statistics: statistics.inquiryStatistics.huHU,
        systemClassifyA: statistics.systemClassifyA.huHU,
        systemClassifyB: statistics.systemClassifyB.huHU,
        engineerStatistics: statistics.engineerStatistics.huHU,
        defectivePart: statistics.defectivePart.huHU,
        replyInquiryStatistics: statistics.replyInquiryStatistics.huHU,
        passRateStatistics: statistics.passRateStatistics.huHU,
        timelyResponseRateStatistics: statistics.timelyResponseRateStatistics.huHU,
        responseRateStatistics24Hour: statistics.responseRateStatistics24Hour.huHU,
        customsClearance135Rate: statistics.customsClearance135Rate.huHU,
        accumulatedClosureRate: statistics.accumulatedClosureRate.huHU,
        engineerAffiliatedOrganization: statistics.engineerAffiliatedOrganization.huHU,
        EngineerResponseRateReport: statistics.EngineerResponseRateReport.huHU,
    },
    validation: {
        common: {
            required: 'Szükséges tétel',
            numeric: 'Legyen egész',
            decimal: 'Legyen szám',
            positive_interger: 'Legyen pozitív egész',
            up_to_digits: ({ named }: Parameters<MessageFunction>[0]) =>
                `Legfeljebb${named('number')}számjegy`,
            more_than_characters: ({ named }: Parameters<MessageFunction>[0]) =>
                `Több, mint${named('number')}karakter`,
            max_bytes: ({ named }: Parameters<MessageFunction>[0]) =>
                `Nagyobb, mint a max. méret (${named('number')})`,
            less_than_characters: ({ named }: Parameters<MessageFunction>[0]) =>
                `Kevesebb, mint${named('number')}karakter`,
            must_be_characters: ({ named }: Parameters<MessageFunction>[0]) =>
                `Legyen${named('number')}karakter`,
            greater_than: ({ named }: Parameters<MessageFunction>[0]) =>
                `Nagyobb, mint${named('number')}`,
            less_than: ({ named }: Parameters<MessageFunction>[0]) =>
                `Kisebb, mint${named('number')}`,
            up_to_2_decimal_places: 'Legfeljebb 2 tizedesjegy',
            retain_up:
                'Adjon meg legfeljebb 10 pozitív egész számot, és használjon legfeljebb 4 tizedesjegyet',
            retain_ups:
                'Adjon meg legfeljebb 11 pozitív egész számot, és használjon legfeljebb 4 tizedesjegyet',
            input_number: 'Adja meg a számot',
            only_number_a: 'Csak betűket és számokat adhat meg',
            translate: 'Csak betűk',
            positive_integer: 'Töltsön ki legalább egy mezőt',
            at_least_one: 'Töltsön ki legalább egyet',
        },
        tip: {
            incomplete_information: 'Hiányos információ',
        },
    },
    import: {
        template_file: 'Sablonfájl',
        browse: 'Böngészés',
        download_template: 'Letöltési sablon',
        no_file_selected: 'Nincs fájl kiválasztva',
        incorrect_file_type: 'Hibás fájltípus',
        exceed: 'Túllépés',
        upload_success: 'Sikeres feltöltés',
        upload_fail: 'Sikertelen feltöltés',
        again_upload: 'Csak jpg, png, bmp vagy gif képformátumok tölthetőek fel. Töltse fel újra',
        attachmentUpload: 'Csatolmány feltöltése',
        otherAttachmentUpload: 'Egyéb csatolmányok feltöltése',
        supportedFormats: 'Támogatott formátumok',
        preview: 'Előnézet',
    },
    export: {
        noDataToExport: 'Nincs exportálható adat',
    },
    select: {
        all: 'Összes',
    },
    checkbox: {
        select_all: 'Összes kiválasztása',
    },
    dialog: {
        error_message: 'Hibaüzenet',
        one_year: 'Dokumentumok exportálása legfeljebb egy évre.',
    },
    ymd: {
        year: 'Év',
        month: 'Hónap',
        day: 'Nap',
        january: 'Január',
        february: 'Február',
        march: 'Március',
        april: 'Április',
        may: 'Május',
        june: 'Június',
        july: 'Július',
        august: 'Augusztus',
        september: 'Szeptember',
        october: 'Október',
        november: 'November',
        december: 'December',
    },
};
