export const useEnglishExportDialog = createGlobalState(() => {
    const { t } = useTypedI18n();
    const {
        dialogVisible,
        dialogPropData,
        open,
        events,
        handleAfterLeave,
        handleAfterEnter,
        dialogTitle,
    } = useDialogStates<any>({
        title: () => t('button.export_file_list'),
    });

    return {
        dialogVisible,
        dialogPropData,
        open,
        events,
        handleAfterLeave,
        handleAfterEnter,
        dialogTitle,
    };
});
