import { createPinia } from 'pinia';
import { createHead } from '@vueuse/head';
import { createDefaultSettings } from 'navuelidate';
import { setGlobalOptions } from 'v-demi-request';
import type { DataTableProps } from 'naive-ui';
import { NDataTable } from 'naive-ui';
// import { useRequests } from '../src/pages/auth/oauth/modules/requests';
import App from './App.vue';
import { router } from './utils/router';
import { initQiankun } from '~/utils/qiankun';
import { initToken } from '~/utils/token';
import { i18n, initI18nScope } from '~/utils/i18n';

import '@unocss/reset/normalize.css';
import './styles/main.css';
import 'uno.css';
import './utils/dayjs';
import { initAxios } from '~/utils/http/axios';
import { useCommonStore } from '~/stores/common';
import { baseUrl } from '~/utils/http/request';
import { vWatermark } from '~/utils/watermark';

const { getRequest } = useApis();

// const { handler } = useResponseHandler();
// const { getRedirectUrl } = useRequests();
console.log(NDataTable);

((NDataTable as any).props as DataTableProps).scrollbarProps = {
    trigger: 'none',
};

export const mode = import.meta.env.MODE;
const head = createHead();

(window as any).global = window;

initQiankun();
initAxios();
createDefaultSettings({
    formItemGiProps: {
        labelAlign: 'left',
    },
});
setGlobalOptions({
    retry: false,
});
const pinia = createPinia();
const app = createApp(App);

const getRedirectUrl = async (from?: string) => {
    return await getRequest<{ state: string } | undefined, string>(
        `${baseUrl}/auth/oauth/authorize`,
        from ? { state: from } : undefined,
        {
            headers: {
                Authorization: null,
                'X-Timezone': Intl.DateTimeFormat().resolvedOptions().timeZone,
            },
        },
    );
};
router.beforeEach((to, from, next) => {
    const commonStore = useCommonStore();
    const url = new URL(window.location.href);

    if (url.pathname === '/auth/oauth' && !url.search.includes('?code=')) {
        getRedirectUrl(url.pathname).then((result: any) => {
            if (result.res) {
                window.location.replace(result.res);
            }
        });
    }

    if (url.searchParams.has('showMenu')) {
        commonStore.showMenu = url.searchParams.get('showMenu') === '1';
    }
    next();
});
app.directive('watermark', vWatermark);
app.use(router).use(pinia).use(head).use(i18n).mount('#app');
initToken();
initI18nScope();
