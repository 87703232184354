import type { MessageFunction } from 'vue-i18n';
import homepage from './pages/homepage/index';
import tech from './pages/tech/index';
import statistics from './pages/statistics/index';
import Info from './pages/info/index';
import system from './pages/system/index';
// 英
export default {
    license: {
        powered_by: 'Powered by SMIL',
    },
    auth: {
        login: 'Login',
        username: 'User name',
        username_placeholder: 'Enter username',
        password: 'Password',
        password_placeholder: 'Enter password',
        code: 'Verify code',
        code_placeholder: 'Enter verify code',
        logout: 'Logout',
        logout_success: 'Logout success',
        day: 'Day',
        password_expiration: 'The password has expired, please modify it',
        expire: 'Remaining until password expiration',
        conseils:
            'The password must contain uppercase letters, lowercase letters, numbers, and special characters ({msg}), and the length of the password should be between 8 to 16 characters',
        brand_Selection: 'Brand Selection',
        unread_notices: 'Unread Notices',
        new_cases: 'New Cases',
        unanswered_cases: 'Unanswered Cases',
        overdue_cases: 'Overdue Cases',
    },
    button: {
        editing_position: 'Editing position',
        query: 'Search',
        old_password: 'Old password',
        new_password: 'New password',
        confirm_password: 'Confirm password',
        change_password: 'Change password',
        show_theme_editor: 'Show/Hide theme editor',
        button: 'Button',
        about: 'About',
        back: 'Back',
        go: 'GO',
        home: 'Home',
        confirm: 'Confirm',
        modify: 'Modify',
        edit: 'Edit',
        new: 'Add',
        cancel: 'Cancel',
        close: 'Closed',
        search: 'Search',
        reset: 'Reset',
        delete: 'Delete',
        add: 'Add',
        collapse: 'Collapse',
        expand: 'Expand',
        download_template: 'Download template',
        batch_import: 'Batch import',
        import: 'Import',
        export: 'Export',
        export_fail: 'Export fail',
        detailed: 'Detail',
        details: 'Details',
        download: 'Download',
        toView: 'View',
        toViews: 'View picture',
        uploadAttachments: 'Upload',
        selectFile: 'Select file',
        fillingInstructions: 'Filling instructions',
        release: 'Release',
        release_recall: 'Release Recall',
        save: 'Save',
        send: 'Send',
        tips: 'Tips',
        send_back: 'Return',
        examine: 'examine',
        void: 'Invalid',
        more: 'More',
        refresh: 'Refresh',
        selects: 'Please select',
        serial_number: 'Serial number',
        upload_progress: 'Upload progress',
        invalid: 'Invalid',
        daily_record: 'Daily record',
        operation_type: 'Operation type',
        field_name: 'Field name',
        edit_role: 'Edit role',
        user_role: 'User role',
        assignment_role: 'Role',
        post: 'Post',
        sending: 'Sending',
        reset_password: 'Reset password',
        display_or_not: 'Display or not',
        document: 'Document',
        specificTemplates: 'specific Templates',
        transferOrder: 'Transfer case',
        forwarding: 'Forwarding',
        one_click_notification: 'One Click Notification',
        english_export: 'English Export',
        oneclick_download: 'One Click Download',
        passwordmsga:
            'Must contain uppercase letters, lowercase letters, numbers, and special symbols （@\\$!_~\\&*）',
        passwordmsgb: 'Password length must be between 8 and 16 characters',
        passwordmsgc: 'Cannot be the same as the previous six passwords',
    },
    radio: {
        enabled: 'Enabled',
        disabled: 'Disabled',
        catalogue: 'Catalogue',
        individual: 'Individual',
        all: 'All',
        yes: 'Yes',
        no: 'No',
    },
    dropdown: {
        close_others: 'Close others',
        close_right: 'Close right',
        close_left: 'Close left',
    },
    table: {
        action: 'Action',
        explain: 'description',
        fileName: 'Attachment name',
        size: 'Size(MB)',
        uploader: 'upload by',
        uploadTime: 'Upload time',
        system: 'System',
        caption: 'Caption',
        categorization: 'Categorization',
        link: 'Link',
        forwarding_time: 'Forwarding Time',
        forwarder: 'Forwarder',
        send_to: 'Send To',
        mode: 'Mode',
    },
    pagination: {
        pages: ({ named }: Parameters<MessageFunction>[0]) => `${named('number')}/page`,
        total: ({ named }: Parameters<MessageFunction>[0]) => `${named('number')} records in total`,
        vinCount: ({ named }: Parameters<MessageFunction>[0]) =>
            `${named('number')} vehicles in total`,
    },
    confirm: {
        confirm_deletion: 'Confirm deletion?',
        confirm_void: 'Confirm void?',
        confirm_release: 'Confirm release?',
        ack: 'Confirm',
        cancel: 'Cancel',
        non_records: 'Please choose at least one record',
    },
    request: {
        failed_placeholder: 'Request failed, backend error',
        success_placeholder: 'Request success',
        auth: {
            login_success: 'Login success',
        },
        error: {
            '404': 'Request url not found',
            '403': 'Permission denied',
        },
    },
    page: {
        global: {
            page_search: 'Page search',
        },
        error: {
            not_found: 'Not found',
            not_found_des: 'There is nothing here',
            auth_failed: 'Auth failed',
            auth_failed_des: 'This page is not reachable',
            file_format_error: 'File format error!',
            preview_failed: 'Preview failed! File abnormal!',
        },
        homepage: {
            homepage: homepage.homepage.enUS,
        },
        system: {
            menu: system.menu.enUS,
            dict: system.dict.enUS,
            dept: system.dept.enUS,
            role: system.role.enUS,
            user: system.user.enUS,
        },
        info: {
            announcement: Info.announcement.announcement.enUS,
            document: Info.document.document.enUS,
        },
        tech: {
            quality: {
                problemTracking: tech.quality.problemTracking.enUS,
                recallActivity: tech.quality.recallActivity.enUS,
                abnormalClaimTracking: tech.quality.abnormalClaimTracking.enUS,
            },
            management: {
                inquiryOd: tech.management.inquiry.enUS,
                permission: tech.management.permission.enUS,
                onSiteSupportHq: tech.management.onSiteSupport.enUS,
                progHistoryHq: tech.management.progHistory.enUS,
                salesRecordHq: tech.management.salesRecord.enUS,
                vehicleInfoHq: tech.management.vehicleInfo.enUS,
                claimHq: tech.management.claim.enUS,
                filterHq: tech.management.filter.enUS,
                newCarlssue: tech.management.newCarIssue.enUS,
                newCarDefinition: tech.management.newCarDefinition.enUS,
                vehicleProductionInfo: tech.management.vehicleProductionInfo.enUS,
                programmingHistoryInformationQuery:
                    tech.management.programmingHistoryInformationQuery.enUS,
                ecuControllerInformationQuery: tech.management.ecuControllerInformationQuery.enUS,
            },
        },
        components: {
            businessComponents: tech.management.businessComponents.enUS,
        },
        statistics: statistics.inquiryStatistics.enUS,
        systemClassifyA: statistics.systemClassifyA.enUS,
        systemClassifyB: statistics.systemClassifyB.enUS,
        engineerStatistics: statistics.engineerStatistics.enUS,
        defectivePart: statistics.defectivePart.enUS,
        replyInquiryStatistics: statistics.replyInquiryStatistics.enUS,
        passRateStatistics: statistics.passRateStatistics.enUS,
        timelyResponseRateStatistics: statistics.timelyResponseRateStatistics.enUS,
        responseRateStatistics24Hour: statistics.responseRateStatistics24Hour.enUS,
        customsClearance135Rate: statistics.customsClearance135Rate.enUS,
        accumulatedClosureRate: statistics.accumulatedClosureRate.enUS,
        engineerAffiliatedOrganization: statistics.engineerAffiliatedOrganization.enUS,
        EngineerResponseRateReport: statistics.EngineerResponseRateReport.enUS,
    },
    validation: {
        common: {
            required: 'Item is required',
            numeric: 'Must be integer',
            decimal: 'Must be number',
            positive_interger: 'Must be positive interger',
            up_to_digits: ({ named }: Parameters<MessageFunction>[0]) =>
                `Up to ${named('number')} digits`,
            more_than_characters: ({ named }: Parameters<MessageFunction>[0]) =>
                `More than ${named('number')} characters`,
            max_bytes: ({ named }: Parameters<MessageFunction>[0]) =>
                `Exceed max bytes (${named('number')})`,
            less_than_characters: ({ named }: Parameters<MessageFunction>[0]) =>
                `Less than ${named('number')} characters`,
            must_be_characters: ({ named }: Parameters<MessageFunction>[0]) =>
                `Must be ${named('number')} characters`,
            greater_than: ({ named }: Parameters<MessageFunction>[0]) =>
                `Greater than ${named('number')}`,
            less_than: ({ named }: Parameters<MessageFunction>[0]) =>
                `Less than ${named('number')}`,
            up_to_2_decimal_places: 'Up to 2 decimal places',
            retain_up: 'Enter up to 10 positive integers and retain up to 4 decimal places',
            retain_ups: 'Enter up to 11 positive integers, and retain up to 4 decimal places',
            input_number: 'Please enter the number',
            only_number_a: 'Only letters or numbers can be entered',
            translate: 'Only letters',
            positive_integer: 'Enter at least one field',
            at_least_one: 'Fill in at least one',
        },
        tip: {
            incomplete_information: 'Incomplete Information',
        },
    },
    import: {
        template_file: 'Template file',
        browse: 'Browse',
        download_template: 'Download template',
        no_file_selected: 'No file selected',
        incorrect_file_type: 'Incorrect file type',
        exceed: 'Exceed',
        upload_success: 'Upload success',
        upload_fail: 'Upload fail',
        again_upload:
            'Only image files in jpg, png, bmp, or gif formats can be uploaded. Please upload again',
        attachmentUpload: 'Attachment Upload',
        otherAttachmentUpload: 'Other Attachment Upload',
        supportedFormats: 'Supported Formats',
        preview: 'Preview',
    },
    export: {
        noDataToExport: 'No data to export',
    },
    select: {
        all: 'All',
    },
    checkbox: {
        select_all: 'Select all',
    },
    dialog: {
        error_message: 'Error message',
        one_year: 'Export documents for up to one year.',
    },
    ymd: {
        day: 'day',
        month: 'month',
        year: 'year',
        january: 'Jan',
        february: 'Feb',
        march: 'Mar',
        april: 'Apr',
        may: 'May',
        june: 'Jun',
        july: 'Jul',
        august: 'Aug',
        september: 'Sep',
        october: 'Oct',
        november: 'Nov',
        december: 'Dec',
    },
};
