export default {
    // 中
    zhCN: {
        name: '名称',
        sort: '排序',
        parent_directory: '上级目录',
        new_directory: '新建目录',
        edit_directory: '编辑目录',
        directory: '文档目录',
        primary_directory: '一级目录',
        secondary_directory: '二级目录',
        document_title: '文档标题',
        vehicle_platform: '车型平台',
        status: '状态',
        create_time: '创建时间',
        model: '车型',
        attachment_name: '附件名称',
        document_information: '文档信息',
        document_tag: '文档标签',
        operation_record: '操作记录',
        operating_time: '操作时间',
        operator: '操作人',
        publishing_department: '发布部门',
        publisher: '发布人',
        person_in_charge: '负责人',
        remarks: '备注',
        publish_objects: '发布对象',
        publish_mode: '发布模式',
        whether_the_document_is_downloadable: '文档是否可下载',
        release_status: '发布状态',
        case_label: '案例标签',
        left_frame: '左驾',
        right_frame: '右驾',
        cab_Position: '驾驶室位置',
        release_time: '发布时间',
        releases: '版本',
        regional_releases: '区域版本',
    },
    // 英
    enUS: {
        name: 'directory name',
        sort: 'Sort by',
        parent_directory: 'Parent directory',
        new_directory: 'New directory',
        edit_directory: 'Edit directory',
        directory: 'Directory',
        primary_directory: 'Primary directory',
        secondary_directory: 'Secondary directory',
        document_title: 'Title',
        vehicle_platform: 'Vehicle platform',
        status: 'Status',
        create_time: 'Create time',
        model: 'Model',
        attachment_name: 'File name',
        document_information: 'Document information',
        document_tag: 'Document tag',
        operation_record: 'Operation record',
        operating_time: 'Operating time',
        operator: 'Operator',
        publishing_department: 'Publishing department',
        publisher: 'Publisher',
        person_in_charge: 'Owner',
        remarks: 'Remarks',
        publish_objects: 'Release to',
        publish_mode: 'Release mode',
        whether_the_document_is_downloadable: 'Downloadable',
        release_status: 'Release status',
        case_label: 'Case Tags',
        left_frame: 'Left Drive',
        right_frame: 'Right Drive',
        cab_Position: 'Cab Position',
        release_time: 'Release Time',
        releases: 'Releases',
        regional_releases: 'Regional Releases',
    },
    // 德
    deDE: {
        name: 'Name',
        sort: 'Sortierung',
        parent_directory: 'übergeordnetes Verzeichnis',
        new_directory: 'Neues Verzeichnis',
        edit_directory: 'Verzeichnis bearbeiten',
        directory: 'Verzeichnis',
        primary_directory: 'Hauptverzeichnis',
        secondary_directory: 'zweites Verzeichnis',
        document_title: 'Dokumententitel',
        vehicle_platform: 'Fahrzeugplattform',
        status: 'Status',
        create_time: 'Erstellungszeit',
        model: 'Modell',
        attachment_name: 'Dateiname',
        document_information: 'Dokumenteninformationen',
        document_tag: 'Dokumenten-Tag',
        operation_record: 'Betriebsaufzeichnung',
        operating_time: 'Betriebszeit',
        operator: 'Betriebsführer',
        publishing_department: 'Veröffentlichende Abteilung',
        publisher: 'Veröffentlicher',
        person_in_charge: 'Verantwortliche Person',
        remarks: 'Bemerkungen',
        publish_objects: 'Freigabe an',
        publish_mode: 'Freigabemodus',
        whether_the_document_is_downloadable: 'Ob das Dokument heruntergeladen werden kann',
        release_status: 'Freigabestatus',
        case_label: 'Fallmarken',
        left_frame: 'Linkslenker',
        right_frame: 'Rechtslenker',
        cab_Position: 'Fahrerhausposition',
        release_time: 'Veröffentlichungszeit',
        releases: 'Veröffentlichungen',
        regional_releases: 'Regionale Veröffentlichungen',
    },
    // 法
    frFR: {
        name: 'Nom du répertoire',
        sort: 'Tier par',
        parent_directory: 'Répertoire parent',
        new_directory: 'Nouveau dossier',
        edit_directory: 'Modifier le nom du dossier',
        directory: 'Dossier',
        primary_directory: 'Répertoire principal',
        secondary_directory: 'Répertoire secondaire',
        document_title: 'Titre',
        vehicle_platform: 'Plate-forme de véhicule',
        status: 'Statut',
        create_time: 'Temps de création',
        model: 'Modèle',
        attachment_name: 'Nom du fichier',
        document_information: 'Informations sur le document',
        document_tag: 'Tag du document',
        operation_record: 'Enregistrement des opérations',
        operating_time: 'Temps d\'\'opération',
        operator: 'Opérateur',
        publishing_department: 'Département de publication',
        publisher: 'Editeur',
        person_in_charge: 'Titulaire',
        remarks: 'Remarques',
        publish_objects: 'Publié pour',
        publish_mode: 'Mode de publication',
        whether_the_document_is_downloadable: 'Téléchargable',
        release_status: 'Statut de publication',
        case_label: 'Étiquettes de cas',
        left_frame: 'Volant à gauche',
        right_frame: 'Volant à droite',
        cab_Position: 'Position de la cabine',
        release_time: 'Heure de publication',
        releases: 'Versions ou Publications',
        regional_releases: 'Sorties régionales',
    },
    // 意大利
    itIT: {
        name: 'Nome',
        sort: 'Ordinamento',
        parent_directory: 'Directory genitore',
        new_directory: 'Nuova directory',
        edit_directory: 'Modifica directory',
        directory: 'Directory del documento',
        primary_directory: 'Directory di primo livello',
        secondary_directory: 'Directory di secondo livello',
        document_title: 'Titolo del documento',
        vehicle_platform: 'Piattaforma veicolo',
        status: 'Stato',
        create_time: 'Data di creazione',
        model: 'Modello',
        attachment_name: 'Nome allegato',
        document_information: 'Informazioni documento',
        document_tag: 'Tag del documento',
        operation_record: 'Registro operazioni',
        operating_time: 'Tempo di operazione',
        operator: 'Operatore',
        publishing_department: 'Reparto pubblicazioni',
        publisher: 'Pubblicatore',
        person_in_charge: 'Responsabile',
        remarks: 'Note',
        publish_objects: 'Oggetti di pubblicazione',
        publish_mode: 'Modalità di pubblicazione',
        whether_the_document_is_downloadable: 'Il documento è scaricabile?',
        release_status: 'Stato di pubblicazione',
        case_label: 'Etichette dei casi',
        left_frame: 'Volante a sinistra',
        right_frame: 'Volante a destra',
        cab_Position: 'Posizione della cabina',
        release_time: 'Ora di rilascio',
        releases: 'Rilasci',
        regional_releases: 'Rilasci regionali',
    },
    // 荷兰
    nlNL: {
        name: 'Naam',
        sort: 'Sorteren',
        parent_directory: 'Ouder directory',
        new_directory: 'Nieuwe directory',
        edit_directory: 'Bewerk directory',
        directory: 'Directory',
        primary_directory: 'Primaire directory',
        secondary_directory: 'Secundaire directory',
        document_title: 'Document titel',
        vehicle_platform: 'Voertuig platform',
        status: 'Status',
        create_time: 'Aanmaaktijd',
        model: 'Model',
        attachment_name: 'Bestandsnaam',
        document_information: 'Document informatie',
        document_tag: 'Document tag',
        operation_record: 'Bedieningsrecord',
        operating_time: 'Bedieningstijd',
        operator: 'Operator',
        publishing_department: 'Publicatie afdeling',
        publisher: 'Uitgever',
        person_in_charge: 'Verantwoordelijke persoon',
        remarks: 'Opmerkingen',
        publish_objects: 'Publiceer naar',
        publish_mode: 'Publiceer modus',
        whether_the_document_is_downloadable: 'Of het document downloadbaar is',
        release_status: 'Publicatie status',
        case_label: 'Case-tags',
        left_frame: 'Links stuur',
        right_frame: 'Rechts stuur',
        cab_Position: 'Cabine positie',
        release_time: 'Vrijgavetijd',
        releases: 'Releases of Publicaties',
        regional_releases: 'Regionale releases',
    },
    // 越南
    viVN: {
        name: 'Tên thư mục',
        sort: 'Sắp xếp bởi',
        parent_directory: 'Thư mục cha',
        new_directory: 'Tạo thư mục mới',
        edit_directory: 'Chỉnh sửa thư mục',
        directory: 'Thư mục tài liệu',
        primary_directory: 'Thư mục chính',
        secondary_directory: 'Thư mục phụ',
        document_title: 'Tiêu đề',
        vehicle_platform: 'Nền tảng xe',
        status: 'Trạng thái',
        create_time: 'Thời gian tạo',
        model: 'Loại xe',
        attachment_name: 'Tên tệp đính kèm',
        document_information: 'Thông tin tài liệu',
        document_tag: 'Thẻ tài liệu',
        operation_record: 'Lịch sử hoạt động',
        operating_time: 'Thời gian hoạt động',
        operator: 'Người thao tác',
        publishing_department: 'Bô phận phát hành',
        publisher: 'Nhà xuất bản',
        person_in_charge: 'Chủ sở hữu',
        remarks: 'Ghi chú',
        publish_objects: 'Phát hành tới',
        publish_mode: 'Chế độ phát hành',
        whether_the_document_is_downloadable: 'Có thể tải về',
        release_status: 'Trạng thái phát hành',
        case_label: 'Nhãn trường hợp',
        left_frame: 'Lái bên trái',
        right_frame: 'Lái bên phải',
        cab_Position: 'Vị trí cabin',
        release_time: 'Thời gian phát hành',
        releases: 'Phiên bản hoặc Phát hành',
        regional_releases: 'Phát hành khu vực',
    },
    // 西班牙
    esES: {
        name: 'Nombre',
        sort: 'Orden',
        parent_directory: 'Directorio superior',
        new_directory: 'Crear directorio',
        edit_directory: 'Editar directorio',
        directory: 'Directorio de documentos',
        primary_directory: 'Directorio de nivel 1',
        secondary_directory: 'Directorio de nivel 2',
        document_title: 'Título del documento',
        vehicle_platform: 'Plataforma del vehículo',
        status: 'Estado',
        create_time: 'Fecha de creación',
        model: 'Modelo de vehículo',
        attachment_name: 'Nombre del adjunto',
        document_information: 'Información del documento',
        document_tag: 'Etiquetas del documento',
        operation_record: 'Registro de operaciones',
        operating_time: 'Fecha de operación',
        operator: 'Persona que realizó la operación',
        publishing_department: 'Departamento de publicación',
        publisher: 'Persona que publica',
        person_in_charge: 'Persona responsable',
        remarks: 'Observaciones',
        publish_objects: 'Destinatarios de la publicación',
        publish_mode: 'Modo de publicación',
        whether_the_document_is_downloadable: 'Posibilidad de descargar el documento',
        release_status: 'Estado de publicación',
        case_label: 'Etiquetas de caso',
        left_frame: 'Conducción a la izquierda',
        right_frame: 'Conducción a la derecha',
        cab_Position: 'Posición de la cabina',
        release_time: 'Hora de lanzamiento',
        releases: 'Lanzamientos',
        regional_releases: 'Lanzamientos regionales',
    },
    // 葡萄牙
    ptBR: {
        name: 'Nome',
        sort: 'Ordenação',
        parent_directory: 'Diretório superior',
        new_directory: 'Criar novo diretório',
        edit_directory: 'Editar diretório',
        directory: 'Diretório de documentos',
        primary_directory: 'Diretório de nível 1',
        secondary_directory: 'Diretório de nível 2',
        document_title: 'Título do documento',
        vehicle_platform: 'Plataforma do veículo',
        status: 'Estado',
        create_time: 'Data de criação',
        model: 'Modelo do veículo',
        attachment_name: 'Nome do anexo',
        document_information: 'Informações do documento',
        document_tag: 'Tag do documento',
        operation_record: 'Registro de operação',
        operating_time: 'Tempo de operação',
        operator: 'Operador',
        publishing_department: 'Departamento de publicação',
        publisher: 'Publicador',
        person_in_charge: 'Responsável',
        remarks: 'Observações',
        publish_objects: 'Objetos de publicação',
        publish_mode: 'Modo de publicação',
        whether_the_document_is_downloadable: 'O documento pode ser baixado?',
        release_status: 'Status de publicação',
        case_label: 'Etiquetas de caso',
        left_frame: 'Condução à esquerda',
        right_frame: 'Condução à direita',
        cab_Position: 'Posição da cabine',
        release_time: 'Hora de lançamento',
        releases: 'Lançamentos',
        regional_releases: 'Lançamentos regionais',
    },
    // 波兰
    plPL: {
        name: 'Nazwa katalogu',
        sort: 'Sortowanie',
        parent_directory: 'Katalog nadrzędny',
        new_directory: 'Nowy katalog',
        edit_directory: 'Edytuj katalog',
        directory: 'Katalog',
        primary_directory: 'Katalog główny',
        secondary_directory: 'Katalog dodatkowy',
        document_title: 'Tytuł',
        vehicle_platform: 'Platforma pojazdu',
        status: 'Status',
        create_time: 'Czas założenia',
        model: 'Model',
        attachment_name: 'Nazwa pliku',
        document_information: 'Informacje o dokumencie',
        document_tag: 'Znacznik dokumentu',
        operation_record: 'Zapis operacji',
        operating_time: 'Osoba publikująca',
        operator: 'Operator',
        publishing_department: 'Dział publikujący',
        publisher: 'Osoba publikująca',
        person_in_charge: 'Właściciel',
        remarks: 'Uwagi',
        publish_objects: 'Opublikowane do',
        publish_mode: 'Tryb publikacji',
        whether_the_document_is_downloadable: 'Czy można pobrać?',
        release_status: 'Status publikacji',
        case_label: 'Etykiety przypadków',
        left_frame: 'Kierowanie z lewej',
        right_frame: 'Kierowanie z prawej',
        cab_Position: 'Pozycja kabiny',
        release_time: 'Czas wydania',
        releases: 'Wydania',
        regional_releases: 'Wydania regionalne',
    },
    // 土耳其
    trTR: {
        name: 'dizin adı',
        sort: 'Sort by',
        parent_directory: 'Üst dizini',
        new_directory: 'Yeni dizin',
        edit_directory: 'Dizini düzenle',
        directory: 'Dizin',
        primary_directory: 'İlk dizin',
        secondary_directory: 'İkinci dizin',
        document_title: 'Başlık',
        vehicle_platform: 'Araç platformu',
        status: 'Durum',
        create_time: 'Zaman oluştur',
        model: 'Model',
        attachment_name: 'Dosya adı',
        document_information: 'Belge bilgisi',
        document_tag: 'Belge etiketi',
        operation_record: 'Operasyon kayıtları',
        operating_time: 'Operasyon zamanı',
        operator: 'Operatör',
        publishing_department: 'Yayım departmanı',
        publisher: 'Yayıncı',
        person_in_charge: 'Sahibi',
        remarks: 'İşaretler',
        publish_objects: 'Yayınla',
        publish_mode: 'Yayınlama modu',
        whether_the_document_is_downloadable: 'İndirilebilir',
        release_status: 'Yayınlanma durumu',
        case_label: 'Vaka Etiketleri',
        left_frame: 'Sol sürüş',
        right_frame: 'Sağ sürüş',
        cab_Position: 'Kabin pozisyonu',
        release_time: 'Yayın zamanı',
        releases: 'Sürümler veya Yayınlar',
        regional_releases: 'Bölgesel yayınlar',
    },
    // 捷克语
    csCZ: {
        name: 'název dokumentu',
        sort: 'Seřazeno podle',
        parent_directory: 'Nadřazený dokument',
        new_directory: 'Nový dokument',
        edit_directory: 'Upravit dokument',
        directory: 'Adresář',
        primary_directory: 'Primární dokument',
        secondary_directory: 'Sekundární dokument',
        document_title: 'Název',
        vehicle_platform: 'Platforma vozidla',
        status: 'Status',
        create_time: 'Čas vytvoření',
        model: 'Model',
        attachment_name: 'Název souboru',
        document_information: 'Informace o dokumentu',
        document_tag: 'Označení dokumentu',
        operation_record: 'Záznam činnosti',
        operating_time: 'Pracovní čas',
        operator: 'Operátor',
        publishing_department: 'Vydávání publikací',
        publisher: 'Vydavatel',
        person_in_charge: 'Majitel',
        remarks: 'Poznámky',
        publish_objects: 'Uvolnění pro',
        publish_mode: 'Režim vydání',
        whether_the_document_is_downloadable: 'Ke stažení',
        release_status: 'Stav vydání',
        case_label: 'Case Tags',
        left_frame: 'Levé řízení',
        right_frame: 'Pravé řízení',
        cab_Position: 'Pozice kabiny',
        release_time: 'Čas vydání',
        releases: 'Vydání',
        regional_releases: 'Regionální vydání',
    },
    // 匈牙利语
    huHU: {
        name: 'Könyvtár neve',
        sort: 'Rendezési elv',
        parent_directory: 'Szülőkönyvtár',
        new_directory: 'Új könyvtár',
        edit_directory: 'Könyvtár szerkesztése',
        directory: 'Könyvtár',
        primary_directory: 'Első szintű könyvtár',
        secondary_directory: 'Második szintű könyvtár',
        document_title: 'Cím',
        vehicle_platform: 'Járműplatform',
        status: 'Állapot',
        create_time: 'Létrehozás ideje',
        model: 'Modell',
        attachment_name: 'Fájlnév',
        document_information: 'Dokumentumadatok',
        document_tag: 'Dokumentumcímke',
        operation_record: 'Műveleti rekord',
        operating_time: 'Műveleti idő',
        operator: 'Kezelő',
        publishing_department: 'Közzétevő részleg',
        publisher: 'Közzétevő',
        person_in_charge: 'Tulajdonos',
        remarks: 'Megjegyzések',
        publish_objects: 'Közzétéve itt:',
        publish_mode: 'Közzétételi üzemmód',
        whether_the_document_is_downloadable: 'Letölthető',
        release_status: 'Kiadási állapot',
        case_label: 'Esetcímkék',
        left_frame: 'Baloldali vezetés',
        right_frame: 'Jobboldali vezetés',
        cab_Position: 'Fülke pozíció',
        release_time: 'Kiadási idő',
        releases: 'Kiadások',
        regional_releases: 'Regionális kiadások',
    },
    // 俄罗斯
    ruRU: {
        name: 'Имя',
        sort: 'Сортировать по',
        parent_directory: 'Родительский каталог',
        new_directory: 'Новый каталог',
        edit_directory: 'Редактировать каталог',
        directory: 'Каталог',
        primary_directory: 'Основной каталог',
        secondary_directory: 'Вторичный каталог',
        document_title: 'Заголовок документа',
        vehicle_platform: 'Платформа автомобиля',
        status: 'Статус',
        create_time: 'Время создания',
        model: 'Модель',
        attachment_name: 'Имя файла',
        document_information: 'Информация о документе',
        document_tag: 'Метка документа',
        operation_record: 'Запись операций',
        operating_time: 'Время работы',
        operator: 'Оператор',
        publishing_department: 'Отдел публикации',
        publisher: 'Издатель',
        person_in_charge: 'Ответственный',
        remarks: 'Примечания',
        publish_objects: 'Опубликовать для',
        publish_mode: 'Режим публикации',
        whether_the_document_is_downloadable: 'Документ доступен для загрузки',
        release_status: 'Статус выпуска',
        case_label: 'Метка дела',
        left_frame: 'Левая рама',
        right_frame: 'Правая рама',
        cab_Position: 'Расположение кабины',
        release_time: 'Время выпуска',
        releases: 'Релизы',
        regional_releases: 'Региональные выпуски',
    },
};
