import type { MessageFunction } from 'vue-i18n';
import homepage from './pages/homepage/index';
import tech from './pages/tech/index';
import statistics from './pages/statistics/index';
import Info from './pages/info/index';
import system from './pages/system/index';
// 西班牙
export default {
    license: {
        powered_by: 'Desarrollado por SMIL',
    },
    auth: {
        login: 'Iniciar sesión',
        username: 'Nombre de usuario',
        username_placeholder: 'Introduce tu nombre de usuario',
        password: 'Contraseña',
        password_placeholder: 'Introduce tu contraseña',
        code: 'Código',
        code_placeholder: 'Introduce el código',
        logout: 'Cerrar sesión',
        logout_success: 'Cierre de sesión exitoso',
        day: 'día(s)',
        password_expiration: 'La contraseña ha caducado, por favor cambia la contraseña',
        expire: 'Quedan {msg} días para que la contraseña caduque',
        conseils:
            'Debe contener mayúsculas, minúsculas, números y caracteres especiales ({msg}). La longitud de la contraseña debe ser de 8 a 16 caracteres',
        brand_Selection: 'Selección de marca',
        unread_notices: 'Notificaciones no leídas',
        new_cases: 'Nuevos casos',
        unanswered_cases: 'Casos no respondidos',
        overdue_cases: 'Casos vencidos',
    },
    button: {
        query: 'Consultar',
        editing_position: 'Editar puesto',
        show_theme_editor: 'Mostrar/Ocultar editor de temas',
        change_password: 'Cambiar contraseña',
        old_password: 'Contraseña anterior',
        new_password: 'Nueva contraseña',
        confirm_password: 'Confirmar contraseña',
        button: 'Botón',
        about: 'Acerca de',
        back: 'Volver',
        go: 'Ir',
        home: 'Inicio',
        confirm: 'Confirmar',
        modify: 'Modificar',
        edit: 'Editar',
        new: 'Nuevo',
        cancel: 'Cancelar',
        close: 'Cerrar',
        search: 'Buscar',
        reset: 'Restablecer',
        delete: 'Eliminar',
        add: 'Agregar',
        collapse: 'Colapsar',
        expand: 'Expandir',
        download_template: 'Descargar plantilla',
        batch_import: 'Importación por lotes',
        import: 'Importar',
        export: 'Exportar',
        export_fail: 'Fallo en la exportación',
        detailed: 'Detallado',
        details: 'Detalles',
        download: 'Descargar',
        toView: 'Ver',
        toViews: 'Ver imágenes',
        uploadAttachments: 'Cargar archivos adjuntos',
        selectFile: 'Seleccionar archivo',
        fillingInstructions: 'Instrucciones de llenado',
        release: 'Publicar',
        release_recall: 'Retiro de la liberación',
        save: 'Guardar',
        send: 'Enviar',
        tips: 'Consejos',
        send_back: 'Devolver',
        examine: 'Examinar',
        void: 'Anular',
        more: 'Más',
        refresh: 'Actualizar',
        selects: 'Por favor, seleccione',
        serial_number: 'Número de serie',
        upload_progress: 'Progreso de carga',
        invalid: 'Inválido',
        confirm_number: 'Número de factura confirmado:',
        empty_data: 'Borrar datos generados',
        variance_details: 'Detalles de la variación',
        daily_record: 'Registro diario',
        operation_type: 'Tipo de operación',
        field_name: 'Nombre del campo',
        edit_role: 'Editar rol',
        user_role: 'Selección de rol',
        assignment_role: 'Rol',
        post: 'Puesto',
        sending: 'Enviando',
        reset_password: 'Restablecer contraseña',
        display_or_not: 'Mostrar o no',
        document: 'Documento',
        specificTemplates: 'Plantillas específicas',
        transferOrder: 'Orden de transferencia',
        forwarding: 'Reenvío',
        one_click_notification: 'Notificación con un solo clic',
        english_export: 'English Export',
        oneclick_download: 'Descarga con un clic',
        passwordmsga:
            'Debe contener letras mayúsculas, letras minúsculas, números y símbolos especiales （@\\$!_~\\&*）',
        passwordmsgb: 'La longitud de la contraseña debe estar entre 8 y 16 caracteres',
        passwordmsgc: 'No puede ser igual a las seis contraseñas anteriores',
        export_file_list: 'Exportar lista de archivos',
    },
    radio: {
        enabled: 'Habilitado',
        disabled: 'Deshabilitado',
        catalogue: 'Catálogo',
        individual: 'Individual',
        all: 'Todos',
        yes: 'Sí',
        no: 'No',
    },
    dropdown: {
        close_others: 'Cerrar otros',
        close_right: 'Cerrar a la derecha',
        close_left: 'Cerrar a la izquierda',
    },
    table: {
        action: 'Acción',
        explain: 'Explicación',
        fileName: 'Nombre del archivo adjunto',
        size: 'Tamaño (MB)',
        uploader: 'Cargado por',
        uploadTime: 'Tiempo de carga',
        system: 'Sistema',
        forwarding_time: 'Tiempo de reenvío',
        forwarder: 'Promotores',
        send_to: 'Enviado a',
        mode: 'Modo',
    },
    pagination: {
        pages: ({ named }: Parameters<MessageFunction>[0]) => `${named('number')}/página`,
        total: ({ named }: Parameters<MessageFunction>[0]) => `Total de ${named('number')} datos`,
        vinCount: ({ named }: Parameters<MessageFunction>[0]) =>
            `Total ${named('number')} vehículos`,
    },
    confirm: {
        confirm_deletion: 'Confirmar eliminación?',
        confirm_void: 'Confirmar anulación?',
        confirm_release: 'Confirmar publicación?',
        ack: 'Confirmar',
        cancel: 'Cancelar',
        non_records: 'Por favor, seleccione al menos un registro',
    },
    request: {
        failed_placeholder: 'Error de solicitud, error del servidor',
        success_placeholder: 'Solicitud exitosa',
        auth: {
            login_success: 'Inicio de sesión exitoso',
        },
        error: {
            '404': 'Ruta de solicitud no encontrada',
            '403': 'Acceso denegado',
        },
    },
    page: {
        global: {
            page_search: 'Búsqueda de página',
        },
        error: {
            not_found: 'Página no encontrada',
            not_found_des: 'Aquí no hay nada',
            auth_failed: 'Acceso denegado',
            auth_failed_des: 'No tiene permisos para acceder a esta página',
            file_format_error: '¡Formato de archivo anormal!',
            preview_failed: 'Vista previa fallida! Archivo anormal!',
        },
        homepage: {
            homepage: homepage.homepage.esES,
        },
        system: {
            menu: system.menu.esES,
            dict: system.dict.esES,
            dept: system.dept.esES,
            role: system.role.esES,
            user: system.user.esES,
        },
        info: {
            announcement: Info.announcement.announcement.esES,
            document: Info.document.document.esES,
        },
        tech: {
            quality: {
                problemTracking: tech.quality.problemTracking.esES,
                recallActivity: tech.quality.recallActivity.esES,
                abnormalClaimTracking: tech.quality.abnormalClaimTracking.esES,
            },
            management: {
                inquiryOd: tech.management.inquiry.esES,
                permission: tech.management.permission.esES,
                onSiteSupportHq: tech.management.onSiteSupport.esES,
                progHistoryHq: tech.management.progHistory.esES,
                salesRecordHq: tech.management.salesRecord.esES,
                vehicleInfoHq: tech.management.vehicleInfo.esES,
                claimHq: tech.management.claim.esES,
                filterHq: tech.management.filter.esES,
                newCarlssue: tech.management.newCarIssue.esES,
                newCarDefinition: tech.management.newCarDefinition.esES,
                vehicleProductionInfo: tech.management.vehicleProductionInfo.esES,
                programmingHistoryInformationQuery:
                    tech.management.programmingHistoryInformationQuery.esES,
                ecuControllerInformationQuery: tech.management.ecuControllerInformationQuery.esES,
            },
        },
        components: {
            businessComponents: tech.management.businessComponents.esES,
        },
        statistics: statistics.inquiryStatistics.esES,
        systemClassifyA: statistics.systemClassifyA.esES,
        systemClassifyB: statistics.systemClassifyB.esES,
        engineerStatistics: statistics.engineerStatistics.esES,
        defectivePart: statistics.defectivePart.esES,
        replyInquiryStatistics: statistics.replyInquiryStatistics.esES,
        passRateStatistics: statistics.passRateStatistics.esES,
        timelyResponseRateStatistics: statistics.timelyResponseRateStatistics.esES,
        responseRateStatistics24Hour: statistics.responseRateStatistics24Hour.esES,
        customsClearance135Rate: statistics.customsClearance135Rate.esES,
        accumulatedClosureRate: statistics.accumulatedClosureRate.esES,
        engineerAffiliatedOrganization: statistics.engineerAffiliatedOrganization.esES,
        EngineerResponseRateReport: statistics.EngineerResponseRateReport.esES,
    },
    validation: {
        common: {
            required: 'Este elemento es obligatorio',
            numeric: 'Debe ser un número entero',
            decimal: 'Debe ser un número',
            positive_interger: 'Debe ser un número entero positivo',
            up_to_digits: ({ named }: Parameters<MessageFunction>[0]) =>
                `Hasta${named('number')}dígitos`,
            more_than_characters: ({ named }: Parameters<MessageFunction>[0]) =>
                `No se pueden exceder las${named('number')}palabras`,
            max_bytes: ({ named }: Parameters<MessageFunction>[0]) =>
                `Se supera el número máximo de caracteres(${named('number')})`,
            less_than_characters: ({ named }: Parameters<MessageFunction>[0]) =>
                `No menos de${named('number')}palabras`,
            must_be_characters: ({ named }: Parameters<MessageFunction>[0]) =>
                `Debe ser de${named('number')}palabras`,
            greater_than: ({ named }: Parameters<MessageFunction>[0]) =>
                `No puede ser mayor de${named('number')}`,
            less_than: ({ named }: Parameters<MessageFunction>[0]) =>
                `No puede ser inferior a${named('number')}`,
            up_to_2_decimal_places: 'Hasta 2 decimales',
            retain_up: 'Introduzca hasta 10 enteros positivos y mantenga hasta 2 decimales',
            retain_ups: 'Introduzca hasta 11 enteros positivos y mantenga hasta 4 decimales',
            input_number: 'Por favor, introduzca el número',
            only_number_a: 'Solo se pueden introducir letras o números',
            translate: 'Solo se puede ser una letra',
            positive_integer: 'Introduzca al menos un campo',
            at_least_one: 'Rellene al menos uno',
        },
        tip: {
            incomplete_information: 'La información no está completa',
        },
    },
    import: {
        template_file: 'Archivo de plantilla',
        browse: 'Explorar',
        download_template: 'Descargar plantilla',
        no_file_selected: 'Ningún archivo seleccionado',
        incorrect_file_type: 'Tipo de archivo incorrecto',
        exceed: 'Excede',
        upload_success: 'Subida exitosa',
        upload_fail: 'Subida fallida',
        again_upload:
            'Solo se pueden subir archivos de imagen en formato jpg, png, bmp, gif. Por favor, vuelva a subir',
        attachmentUpload: 'Carga de adjunto',
        otherAttachmentUpload: 'Carga de otros archivos adjuntos',
        supportedFormats: 'Formatos admitidos',
        preview: 'Vista previa',
    },
    export: {
        noDataToExport: 'No hay datos exportables',
    },
    select: {
        all: 'Todo',
    },
    checkbox: {
        select_all: 'Selección completa',
    },
    dialog: {
        error_message: 'Mensaje erróneo',
        one_year: 'Exportar documentos de hasta un año.',
    },
    ymd: {
        day: 'día',
        month: 'mes',
        year: 'año',
        january: 'Jan',
        february: 'Feb',
        march: 'Mar',
        april: 'Apr',
        may: 'May',
        june: 'Jun',
        july: 'Jul',
        august: 'Aug',
        september: 'Sep',
        october: 'Oct',
        november: 'Nov',
        december: 'Dec',
    },
};
