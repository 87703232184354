export default {
    // 中
    zhCN: {
        query_time: '查询时间',
        number_of_closedCases: '关单数量',
        percentage_of_closedCases: '关单率',
        SAP: 'SAP',
        organization_code: '组织CODE',
        organization_name: '组织名称',
        country: '国家',
        total_number_of_cases: '案件总数',
        statistical_days: '统计天数',
        organization_name_full: '组织名称(全量查询)',
        num_of_received_documents_r: '收到单据数量（责任方）',
        num_of_received_documents_nr: '收到单据数量（非责任方）',
        num_of_forwarded_documents_r: '转发单据数量（责任方）',
        num_of_forwarded_documents_nr: '转发单据数量（非责任方）',
        inquire_quantity_documents: '问询单据数量',
    },
    // 英
    enUS: {
        query_time: 'Query time',
        number_of_closedCases: 'Number of closedCases',
        percentage_of_closedCases: 'Percentage of closedCases',
        SAP: 'SAP',
        organization_code: 'Organization CODE',
        organization_name: 'Organization Name',
        country: 'Country',
        total_number_of_cases: 'Total Number Of Cases',
        statistical_days: 'Statistical Days',
        organization_name_full: 'Organization Name(full search)',
        num_of_received_documents_r: 'Number of received documents (responsible party)',
        num_of_received_documents_nr: 'Number of received documents (non-responsible party)',
        num_of_forwarded_documents_r: 'Number of forwarded documents (responsible party)',
        num_of_forwarded_documents_nr: 'Number of forwarded documents (non-responsible party)',
        inquire_quantity_documents: 'Number of inquiry documents',
    },
    // 德
    deDE: {
        query_time: 'Abfragezeit',
        number_of_closedCases: 'Anzahl abgeschlossener Fälle',
        percentage_of_closedCases: 'Prozentsatz der abgeschlossenen Fälle',
        SAP: 'SAP',
        organization_code: 'Organisationscode',
        organization_name: 'Organisationsname',
        country: 'Land',
        total_number_of_cases: 'Gesamtzahl der Fälle',
        organization_name_full: 'Organisationsname(full search)',
        num_of_received_documents_r: 'Anzahl empfangener Dokumente (verantwortliche Partei)',
        num_of_received_documents_nr: 'Anzahl empfangener Dokumente (nicht verantwortliche Partei)',
        num_of_forwarded_documents_r: 'Anzahl weitergeleiteter Dokumente (verantwortliche Partei)',
        num_of_forwarded_documents_nr:
            'Anzahl weitergeleiteter Dokumente (nicht verantwortliche Partei)',
        inquire_quantity_documents: 'Anzahl der Anfrage-Dokumente',
    },
    // 法
    frFR: {
        query_time: 'Temps de requête',
        number_of_closedCases: 'Nombre de cas fermés',
        percentage_of_closedCases: 'Pourcentage de cas clos',
        SAP: 'SAP',
        organization_code: 'Code de l\'organisme',
        organization_name: 'Nom de l\'institution',
        country: 'Pays',
        total_number_of_cases: 'Nombre total de cas',
        organization_name_full: 'Nom de l\'institution(full search)',
        num_of_received_documents_r: 'Nombre de documents reçus (partie responsable)',
        num_of_received_documents_nr: 'Nombre de documents reçus (partie non responsable)',
        num_of_forwarded_documents_r: 'Nombre de documents transférés (partie responsable)',
        num_of_forwarded_documents_nr: 'Nombre de documents transférés (partie non responsable)',
        inquire_quantity_documents: 'Nombre de documents d\'enquête',
    },
    // 意大利
    itIT: {
        query_time: 'Tempo di Query',
        number_of_closedCases: 'Numero di casi chiusi',
        percentage_of_closedCases: 'Percentuale di casi chiusi',
        SAP: 'SAP',
        organization_code: 'Codice dell\'organizzazione',
        organization_name: 'Nome dell\'organizzazione',
        country: 'Paese',
        total_number_of_cases: 'Numero totale di casi',
        organization_name_full: 'Nome dell\'organizzazione(full search)',
        num_of_received_documents_r: 'Numero di documenti ricevuti (parte responsabile)',
        num_of_received_documents_nr: 'Numero di documenti ricevuti (parte non responsabile)',
        num_of_forwarded_documents_r: 'Numero di documenti inoltrati (parte responsabile)',
        num_of_forwarded_documents_nr: 'Numero di documenti inoltrati (parte non responsabile)',
        inquire_quantity_documents: 'Numero di documenti di richiesta',
    },
    // 荷兰
    nlNL: {
        query_time: 'Query time',
        number_of_closedCases: 'Aantal gesloten zaken',
        percentage_of_closedCases: 'Percentage gesloten zaken',
        SAP: 'SAP',
        organization_code: 'Organisatiecode',
        organization_name: 'Organisatienaam',
        country: 'Naties',
        total_number_of_cases: 'Totaal aantal gevallen',
        organization_name_full: 'Organisatienaam(full search)',
        num_of_received_documents_r: 'Aantal ontvangen documenten (verantwoordelijke partij)',
        num_of_received_documents_nr: 'Aantal ontvangen documenten (niet-verantwoordelijke partij)',
        num_of_forwarded_documents_r: 'Aantal doorgestuurde documenten (verantwoordelijke partij)',
        num_of_forwarded_documents_nr:
            'Aantal doorgestuurde documenten (niet-verantwoordelijke partij)',
        inquire_quantity_documents: 'Aantal vraagdocumenten',
    },
    // 越南
    viVN: {
        query_time: 'Thời gian truy vấn',
        number_of_closedCases: 'Số lượng case closed',
        percentage_of_closedCases: 'Phần trăm các trường hợp đóng',
        SAP: 'SAP',
        organization_code: 'Mã tổ chức',
        organization_name: 'Tên tổ chức',
        country: 'Quốc gia',
        total_number_of_cases: 'Tổng số trường hợp',
        organization_name_full: 'Tên tổ chức(full search)',
        num_of_received_documents_r: 'Số lượng tài liệu nhận được (bên chịu trách nhiệm)',
        num_of_received_documents_nr: 'Số lượng tài liệu nhận được (bên không chịu trách nhiệm)',
        num_of_forwarded_documents_r: 'Số lượng tài liệu chuyển tiếp (bên chịu trách nhiệm)',
        num_of_forwarded_documents_nr: 'Số lượng tài liệu chuyển tiếp (bên không chịu trách nhiệm)',
        inquire_quantity_documents: 'Số lượng tài liệu hỏi thăm',
    },
    // 西班牙
    esES: {
        query_time: 'Consulta de tiempo',
        number_of_closedCases: 'Número de casos cerrados',
        percentage_of_closedCases: 'Porcentaje de casos cerrados',
        SAP: 'SAP',
        organization_code: 'Código de organización',
        organization_name: 'Nombre de la organización',
        country: 'País',
        total_number_of_cases: 'Número total de casos',
        organization_name_full: 'Nombre de la organización(full search)',
        num_of_received_documents_r: 'Cantidad de documentos recibidos (parte responsable)',
        num_of_received_documents_nr: 'Cantidad de documentos recibidos (parte no responsable)',
        num_of_forwarded_documents_r: 'Cantidad de documentos reenviados (parte responsable)',
        num_of_forwarded_documents_nr: 'Cantidad de documentos reenviados (parte no responsable)',
        inquire_quantity_documents: 'Cantidad de documentos de consulta',
    },
    // 葡萄牙
    ptBR: {
        query_time: 'Tempo de consulta',
        number_of_closedCases: 'Número de casos encerrados',
        percentage_of_closedCases: 'Percentagem de casos encerrados',
        SAP: 'SAP',
        organization_code: 'Código de organização',
        organization_name: 'Nome da organização',
        country: 'País',
        total_number_of_cases: 'Número total de casos',
        organization_name_full: 'Nome da organização(full search)',
        num_of_received_documents_r: 'Número de documentos recebidos (parte responsável)',
        num_of_received_documents_nr: 'Número de documentos recebidos (parte não responsável)',
        num_of_forwarded_documents_r: 'Número de documentos encaminhados (parte responsável)',
        num_of_forwarded_documents_nr: 'Número de documentos encaminhados (parte não responsável)',
        inquire_quantity_documents: 'Número de documentos de consulta',
    },
    // 波兰
    plPL: {
        query_time: 'Data zapytania',
        number_of_closedCases: 'Liczba zamkniętych spraw',
        percentage_of_closedCases: 'Procent zamkniętych spraw',
        SAP: 'SAP',
        organization_code: 'Kod organizacji',
        organization_name: 'Nazwa organizacji',
        country: 'Kraj',
        total_number_of_cases: 'Łączna liczba spraw',
        organization_name_full: 'Nazwa organizacji(full search)',
        num_of_received_documents_r: 'Liczba otrzymanych dokumentów (strona odpowiedzialna)',
        num_of_received_documents_nr: 'Liczba otrzymanych dokumentów (strona nieodpowiedzialna)',
        num_of_forwarded_documents_r: 'Liczba przekazanych dokumentów (strona odpowiedzialna)',
        num_of_forwarded_documents_nr: 'Liczba przekazanych dokumentów (strona nieodpowiedzialna)',
        inquire_quantity_documents: 'Liczba dokumentów zapytań',
    },
    // 土耳其
    trTR: {
        query_time: 'Sorgulama zamanı',
        number_of_closedCases: 'Kapatma sayısı',
        percentage_of_closedCases: 'Kapat oranı',
        SAP: 'SAP',
        organization_code: 'Organizasyon CODE',
        organization_name: 'Organizasyon adı',
        country: 'Ülke',
        total_number_of_cases: 'Toplam vaka sayısı',
        organization_name_full: 'Organizasyon adı(full search)',
        num_of_received_documents_r: 'Alınan belge sayısı (sorumlu taraf)',
        num_of_received_documents_nr: 'Alınan belge sayısı (sorumlu olmayan taraf)',
        num_of_forwarded_documents_r: 'Yönlendirilen belge sayısı (sorumlu taraf)',
        num_of_forwarded_documents_nr: 'Yönlendirilen belge sayısı (sorumlu olmayan taraf)',
        inquire_quantity_documents: 'Sorgulanan belge sayısı',
    },
    // 捷克语
    csCZ: {
        query_time: 'Čas dotazu',
        number_of_closedCases: 'Počet uzavřených případů',
        percentage_of_closedCases: 'Procento uzavřených případů',
        SAP: 'SAP',
        organization_code: 'Kód společnosti',
        organization_name: 'Název organizace',
        country: 'Země',
        total_number_of_cases: 'Celkový počet případů',
        statistical_days: 'Statistické dny',
        organization_name_full: 'Název organizace(full search)',
        num_of_received_documents_r: 'Počet přijatých dokumentů (odpovědná strana)',
        num_of_received_documents_nr: 'Počet přijatých dokumentů (neodpovědná strana)',
        num_of_forwarded_documents_r: 'Počet předaných dokumentů (odpovědná strana)',
        num_of_forwarded_documents_nr: 'Počet předaných dokumentů (neodpovědná strana)',
        inquire_quantity_documents: 'Počet dotazovaných dokumentů',
    },
    // 匈牙利语
    huHU: {
        query_time: 'Lekérdezési idő',
        number_of_closedCases: 'Lezárt esetek száma',
        percentage_of_closedCases: 'Lezárt esetek százaléka',
        SAP: 'SAP',
        organization_code: 'Szervezet kódja',
        organization_name: 'Szervezet neve',
        country: 'Ország',
        total_number_of_cases: 'Összes eset száma',
        statistical_days: 'Statisztikai napok',
        organization_name_full: 'Szervezet neve(full search)',
        num_of_received_documents_r: 'Átvett dokumentumok száma (felelős fél)',
        num_of_received_documents_nr: 'Átvett dokumentumok száma (nem felelős fél)',
        num_of_forwarded_documents_r: 'Továbbított dokumentumok száma (felelős fél)',
        num_of_forwarded_documents_nr: 'Továbbított dokumentumok száma (nem felelős fél)',
        inquire_quantity_documents: 'Kérdéses dokumentumok száma',
    },
    // 俄罗斯语
    ruRU: {
        query_time: 'Время запроса',
        number_of_closedCases: 'Количество закрытых дел',
        percentage_of_closedCases: 'Процент закрытых дел',
        SAP: 'SAP',
        organization_code: 'Код организации',
        organization_name: 'Название организации',
        country: 'Страна',
        total_number_of_cases: 'Общее количество случаев',
        statistical_days: 'Статистические дни',
        organization_name_full: 'Название организации(full search)',
        num_of_received_documents_r: 'Количество полученных документов (ответственная сторона)',
        num_of_received_documents_nr: 'Количество полученных документов (неответственная сторона)',
        num_of_forwarded_documents_r: 'Количество пересланных документов (ответственная сторона)',
        num_of_forwarded_documents_nr:
            'Количество пересланных документов (неответственная сторона)',
        inquire_quantity_documents: 'Количество запросных документов',
    },
};
