<template>
    <n-data-table
        :columns="columns"
        :data="tableData"
        :loading="loading"
        style="height: 500px"
        :pagination="{
            ...paginationProps,
            pageCount: totalPages,
            itemCount: total,
        }"
        bordered
        mt-4
        v-bind="{
            ...tableEvents,
            ...tableCommonProps,
        }"
    />
</template>

<script lang="ts" setup>
import { useTable } from './table';

const { pageSize, registEvent, current, paginationProps, tableEvents, tableCommonProps } =
    useQueryPage({
        flexHeightTable: true,
        paginationParams: {
            defaultPageSize: 10,
        },
    });

const { tableData, columns, loading, refreshTableData, totalPages, total } = useTable(
    computed(() => {
        return {
            current: current.value,
            size: pageSize.value,
        };
    }),
);

registEvent(() => refreshTableData());
</script>
