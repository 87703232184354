import type { NavDataOrigin } from '~/utils/router';
import { commonRequests } from '~/requests/common/common.request';
import { resHandler } from '~/utils/http/handler';
import { formatRoutes, routeMap } from '~/utils/router';
import { useAuthToken } from '~/utils/token';
import { useStorageLocale } from '~/utils/i18n';

export const useCommonStore = defineStore('common', () => {
    const { authToken, brand } = useAuthToken();

    const { defaultLocale: locale } = useStorageLocale();

    const showMenu = ref(true);
    const loginToPath = ref('');

    const formattedRoutes = ref<NavDataOrigin[]>([]);
    const entriesRoutes = computed(() => {
        return toTreeArray(formattedRoutes.value);
    });

    const currentRouteKey = ref('');
    const cachedRouteList = ref<string[]>([]);
    const iframeList = computed(() =>
        cachedRouteList.value.filter((i) => {
            // return i.includes('ServiceCdc');
            return ['ServiceCdc', 'ServiceEpc', 'ServiceDiagnosis'].some((path) =>
                i.includes(path),
            );
        }),
    );
    const iframeIMList = computed(() =>
        cachedRouteList.value.filter((i) => {
            return ['ImserviceIma', 'ImserviceImb', 'ImserviceImc'].some((path) =>
                i.includes(path),
            );
        }),
    );

    const iframeVINList = computed(() =>
        cachedRouteList.value.filter((i) => {
            return ['InfoDocumentEqmaintain', 'InfoDocumentInterterminal'].some((path) => i.includes(path));
        }),
    );

    watch(
        iframeList.value,
        (val) => {
            console.log(val);
        },
        {
            deep: true,
        },
    );

    const setAuthToken = async (token: string) => {
        authToken.value = token;
    };

    const clearAuth = async () => {
        authToken.value = '';
    };

    const reFetchRoutes = async (value: any) => {
        const response = await commonRequests.getRoutes(
            value ? { brand: value } : { brand: brand.value },
        );
        await resHandler(response, {
            onSuccess: ({ res }) => {
                routeMap.clear();
                formattedRoutes.value = formatRoutes(res);
            },
        });
    };

    return {
        authToken,
        setAuthToken,
        formattedRoutes,
        entriesRoutes,
        locale,
        reFetchRoutes,
        currentRouteKey,
        cachedRouteList,
        clearAuth,
        showMenu,
        iframeList,
        iframeIMList,
        iframeVINList,
        loginToPath,
    };
});

if (import.meta.hot) import.meta.hot.accept(acceptHMRUpdate(useCommonStore, import.meta.hot));
