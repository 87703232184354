<template>
    <NModal
        :show="show"
        preset="card"
        :title="$t('button.change_password')"
        :mask-closable="true"
        :style="{
            width: '520px',
        }"
        @close="colseFun()"
        @mask-click="colseFun()"
    >
        <NSpace vertical>
            <NForm
                ref="formRef"
                :rules="rules"
                :model="model"
                label-placement="left"
                :label-width="maxWith"
                require-mark-placement="right-hanging"
                :show-feedback="false"
            >
                <div v-if="pwdRemindDay" style="margin-bottom: 10px">
                    <div
                        v-if="Number(pwdRemindDay) <= 0"
                        style="color: red; font-size: 20px; text-align: center"
                    >
                        {{
                            loginFlag === '2'
                                ? $t('auth.password_expiration')
                                : 'Please update your password as a new user'
                        }}
                    </div>
                    <div v-else style="font-size: 20px; text-align: center">
                        {{ $t('auth.expire') }}
                        <span style="color: red; margin-left: 5px">{{ pwdRemindDay }}</span>
                        <span>{{ $t('auth.day') }}</span>
                    </div>
                </div>
                <NFormItem :label="$t('button.old_password')" path="oldPassword">
                    <NInput
                        v-model:value="model.oldPassword"
                        type="password"
                        show-password-on="mousedown"
                    />
                </NFormItem>

                <NFormItem m-t="4" :label="$t('button.new_password')" path="newPassword">
                    <NInput
                        v-model:value="model.newPassword"
                        type="password"
                        show-password-on="mousedown"
                    />
                </NFormItem>
                <NFormItem label=" ">
                    <span style="color: #bbb">{{ $t('auth.conseils', { msg: '@$!_~&*' }) }}</span>
                </NFormItem>
                <NFormItem m-t="4" :label="$t('button.confirm_password')" path="confirmPassword">
                    <NInput
                        v-model:value="model.confirmPassword"
                        type="password"
                        show-password-on="mousedown"
                    />
                </NFormItem>
            </NForm>
        </NSpace>

        <template #footer>
            <NSpace justify="center">
                <!-- :disabled="!password" -->
                <NButton @click="confirm">
                    <div>{{ $t('button.confirm') }}</div>
                </NButton>
            </NSpace>
        </template>
    </NModal>
</template>

<script lang="ts" setup>
import { NButton, NInput, useMessage } from 'naive-ui';
import type { FormInst, FormRules } from 'naive-ui';
import { useRequests } from './request';

interface ModelType {
    oldPassword: string;
    newPassword: string;
    confirmPassword: string;
}
const props = defineProps({
    show: {
        type: Boolean,
        default: false,
    },
    pwdRemindDay: {
        default: '',
    },
    loginFlag: {
        default: '2',
    },
});

const emits = defineEmits(['update:close']);

const { modifyPwd } = useRequests();
const message = useMessage();
const { t, locale } = useTypedI18n();
const maxWith = computed(() => (locale.value === 'zh-CN' ? '100' : '160'));
const prompt = computed(() =>
    locale.value === 'zh-CN'
        ? '必须包含大写字母、小写字母、数字、特殊符号（@\\$!_~\\&*），密码长度在 8-16 位'
        : 'The password must contain uppercase letters, lowercase letters, numbers, and special characters (@$!_~&*), and the length of the password should be between 8 to 16 characters',
);
const formRef = ref<FormInst | null>(null);

const model = ref<ModelType>({
    oldPassword: '',
    newPassword: '',
    confirmPassword: '',
});

const rules: FormRules = {
    oldPassword: [
        {
            required: true,
        },
    ],
    newPassword: [
        {
            required: true,
        },
    ],
    confirmPassword: [
        {
            required: true,
        },
    ],
};
const colseFun = () => {
    if (props.pwdRemindDay) {
        if (Number(props.pwdRemindDay) <= 0) {
            message.error(t('auth.password_expiration'));
        } else {
            emits('update:close', false);
        }
    } else {
        emits('update:close', false);
    }
    // 清空
    model.value = {
        oldPassword: '',
        newPassword: '',
        confirmPassword: '',
    };
};
const confirm = () => {
    // 调用表单的 validate 方法进行表单验证
    formRef.value?.validate((valid) => {
        if (!valid) {
            // 如果验证通过，则进行后续操作
            modifyPwd({
                oldPassword: model.value.oldPassword,
                newPassword: model.value.newPassword,
                confirmPassword: model.value.confirmPassword,
            }).then((res) => {
                if (res) {
                    model.value.oldPassword = '';
                    model.value.newPassword = '';
                    model.value.confirmPassword = '';
                    emits('update:close', false);
                }
            });
        } else {
            // 如果验证失败，则提示用户填写必填项
            message.error(t('auth.password_expiration'));
        }
    });
};

// const confirm = () => {
//     formRef.value?.validate((valid) => {
//         if (valid) {
//             modifyPwd({
//                 oldPassword: model.value.oldPassword,
//                 newPassword: model.value.newPassword,
//                 confirmPassword: model.value.confirmPassword,
//             }).then((res) => {
//                 if (res) {
//                     model.value.oldPassword = null;
//                     model.value.newPassword = null;
//                     model.value.confirmPassword = null;
//                     emits('update:close', false);
//                 }
//             });
//         }
//     });

// };
</script>

<style scoped></style>
