import type { MessageFunction } from 'vue-i18n';
import homepage from './pages/homepage/index';
import tech from './pages/tech/index';
import statistics from './pages/statistics/index';
import Info from './pages/info/index';
import system from './pages/system/index';
// 德
export default {
    license: {
        powered_by: 'Angetrieben von SMIL',
    },
    auth: {
        login: 'Anmelden',
        username: 'Nutzername',
        username_placeholder: 'Benutzernamen eingeben',
        password: 'Passwort',
        password_placeholder: 'Passwort eingeben',
        code: 'Überprüfungscode',
        code_placeholder: 'Überprüfungscode eingeben',
        logout: 'Abmelden',
        logout_success: 'Abmeldung erfolgreich',
        day: 'Tag',
        password_expiration: 'Das Passwort ist abgelaufen, bitte ändern Sie es',
        expire: 'Bleiben bis zum Ablauf des Passworts',
        conseils:
            'Das Passwort muss Großbuchstaben, Kleinbuchstaben, Zahlen und Sonderzeichen ({msg}) enthalten, und die Länge des Passworts sollte zwischen 8 bis 16 Zeichen liegen.',
        brand_Selection: 'Markenauswahl',
        unread_notices: 'Ungelesene Hinweise',
        new_cases: 'Neue Fälle',
        unanswered_cases: 'Unbeantwortete Fälle',
        overdue_cases: 'Überfällige Fälle',
    },
    button: {
        query: 'Abfragen',
        editing_position: 'Bearbeitungsposition',
        show_theme_editor: 'Themeneditor ein-/ausblenden',
        change_password: 'Passwort ändern',
        old_password: 'Altes Passwort',
        new_password: 'Neues Passwort',
        confirm_password: 'Passwort bestätigen',
        button: 'Taste',
        about: 'Über',
        back: 'Zurück',
        go: 'LOS',
        home: 'Startseite',
        confirm: 'Bestätigen',
        modify: 'Bearbeiten',
        edit: 'Bearbeiten',
        new: 'Neu',
        cancel: 'Abbrechen',
        close: 'Geschlossen',
        search: 'Suche',
        reset: 'Zurücksetzen',
        delete: 'Löschen',
        add: 'Hinzufügen',
        collapse: 'Zusammenklappen',
        expand: 'Mehr',
        download_template: 'Vorlage herunterladen',
        batch_import: 'Stapelimport',
        import: 'Importieren',
        export: 'Exportieren',
        export_fail: 'Export fehlgeschlagen',
        detailed: 'Detail',
        details: 'Details',
        download: 'Herunterladen',
        toView: 'Prüfen',
        uploadAttachments: 'Anhänge hochladen',
        selectFile: 'Datei auswählen',
        fillingInstructions: 'Füllanweisungen',
        release: 'Freigeben',
        release_recall: 'release_recall',
        save: 'Speichern',
        send: 'Senden',
        tips: 'Tipps',
        send_back: 'Zurücksenden',
        examine: 'überprüfen',
        void: 'Ungültig',
        more: 'Mehr',
        refresh: 'Aktualisieren',
        selects: 'Bitte auswählen',
        serial_number: 'Nr.',
        upload_progress: 'Upload-Fortschritt',
        invalid: 'Ungültig',
        daily_record: 'Tagesaufzeichnung',
        operation_type: 'Operationstyp',
        field_name: 'Feldname',
        edit_role: 'Rolle bearbeiten',
        user_role: 'Benutzerrolle',
        assignment_role: 'Rolle einstellen',
        post: 'Post',
        sending: 'Senden',
        reset_password: 'Passwort zurücksetzen',
        display_or_not: 'Anzeige oder nicht',
        document: 'Dokument',
        specificTemplates: 'spezifische Vorlagen',
        transferOrder: 'Transferauftrag',
        forwarding: 'Weiterleitung',
        one_click_notification: 'One Click Benachrichtigung',
        english_export: 'English Export',
        oneclick_download: 'Ein-Klick-Download',
        passwordmsga:
            'Muss Großbuchstaben, Kleinbuchstaben, Zahlen und Sonderzeichen （@\\$!_~\\&*） enthalten',
        passwordmsgb: 'Die Passwortlänge muss zwischen 8 und 16 Zeichen liegen',
        passwordmsgc: 'Darf nicht mit den vorherigen sechs Passwörtern identisch sein',
        export_file_list: 'Dateiliste exportieren',
    },
    radio: {
        enabled: 'Aktiviert',
        disabled: 'Deaktiviert',
        catalogue: 'Katalog',
        individual: 'Individuell',
        all: 'Alle',
        yes: 'Ja',
        no: 'Nein',
    },
    dropdown: {
        close_others: 'Andere schließen',
        close_right: 'Nach rechts schließen',
        close_left: 'Nach links schließen',
    },
    table: {
        action: 'Aktion',
        explain: 'Erklärung',
        fileName: 'Bezeichnung des Anhangs',
        size: 'Größe(MB)',
        uploader: 'Uploader',
        uploadTime: 'Uploadzeit',
        system: 'System',
        forwarding_time: 'Weiterleitungszeit',
        forwarder: 'Spediteur',
        send_to: 'Senden an',
        mode: 'Modus',
    },
    pagination: {
        pages: ({ named }: Parameters<MessageFunction>[0]) => `${named('number')}/page`,
        total: ({ named }: Parameters<MessageFunction>[0]) => `${named('number')} artikel Daten`,
        vinCount: ({ named }: Parameters<MessageFunction>[0]) =>
            `${named('number')} Fahrzeuge insgesamt`,
    },
    confirm: {
        confirm_deletion: 'Löschen bestätigen?',
        confirm_void: 'Freigabe bestätigen?',
        confirm_release: 'Freigabe bestätigen?',
        ack: 'Bestätigen',
        cancel: 'Abbrechen',
        non_records: 'Bitte wählen Sie mindestens einen Eintrag',
    },
    request: {
        failed_placeholder: 'Anfrage fehlgeschlagen, Backend-Fehler',
        success_placeholder: 'Anfrage erfolgreich',
        auth: {
            login_success: 'Anmeldung erfolgreich',
        },
        error: {
            '404': 'Anforderungs-URL nicht gefunden',
            '403': 'Zugriff verweigert',
        },
    },
    page: {
        global: {
            page_search: 'Seitensuche',
        },
        error: {
            not_found: 'Nicht gefunden',
            not_found_des: 'Hier gibt es nichts',
            auth_failed: 'Authentifizierung fehlgeschlagen',
            auth_failed_des: 'Diese Seite ist nicht erreichbar',
            file_format_error: 'Dateiformatfehler!',
            preview_failed: 'Vorschau fehlgeschlagen! Datei abnorm!',
        },
        homepage: {
            homepage: homepage.homepage.deDE,
        },
        system: {
            menu: system.menu.deDE,
            dict: system.dict.deDE,
            dept: system.dept.deDE,
            role: system.role.deDE,
            user: system.user.deDE,
        },
        info: {
            announcement: Info.announcement.announcement.deDE,
            document: Info.document.document.deDE,
        },
        tech: {
            quality: {
                problemTracking: tech.quality.problemTracking.deDE,
                recallActivity: tech.quality.recallActivity.deDE,
                abnormalClaimTracking: tech.quality.abnormalClaimTracking.deDE,
            },
            management: {
                inquiryOd: tech.management.inquiry.deDE,
                permission: tech.management.permission.deDE,
                onSiteSupportHq: tech.management.onSiteSupport.deDE,
                progHistoryHq: tech.management.progHistory.deDE,
                salesRecordHq: tech.management.salesRecord.deDE,
                vehicleInfoHq: tech.management.vehicleInfo.deDE,
                claimHq: tech.management.claim.deDE,
                filterHq: tech.management.filter.deDE,
                newCarlssue: tech.management.newCarIssue.deDE,
                newCarDefinition: tech.management.newCarDefinition.deDE,
                vehicleProductionInfo: tech.management.vehicleProductionInfo.deDE,
                programmingHistoryInformationQuery: tech.management.programmingHistoryInformationQuery.deDE,
                ecuControllerInformationQuery: tech.management.ecuControllerInformationQuery.deDE,
            },
        },
        components: {
            businessComponents: tech.management.businessComponents.deDE,
        },
        statistics: statistics.inquiryStatistics.deDE,
        systemClassifyA: statistics.systemClassifyA.deDE,
        systemClassifyB: statistics.systemClassifyB.deDE,
        engineerStatistics: statistics.engineerStatistics.deDE,
        defectivePart: statistics.defectivePart.deDE,
        replyInquiryStatistics: statistics.replyInquiryStatistics.deDE,
        passRateStatistics: statistics.passRateStatistics.deDE,
        timelyResponseRateStatistics: statistics.timelyResponseRateStatistics.deDE,
        responseRateStatistics24Hour: statistics.responseRateStatistics24Hour.deDE,
        customsClearance135Rate: statistics.customsClearance135Rate.deDE,
        accumulatedClosureRate: statistics.accumulatedClosureRate.deDE,
        engineerAffiliatedOrganization: statistics.engineerAffiliatedOrganization.deDE,
        EngineerResponseRateReport: statistics.EngineerResponseRateReport.deDE,
    },
    validation: {
        common: {
            required: 'Artikel ist erforderlich',
            numeric: 'Muss eine ganze Zahl sein',
            decimal: 'Muss eine Zahl sein',
            positive_interger: 'Muss positive ganze Zahl sein',
            up_to_digits: ({ named }: Parameters<MessageFunction>[0]) =>
                `Bis zu ${named('number')} Ziffern`,
            more_than_characters: ({ named }: Parameters<MessageFunction>[0]) =>
                `Mehr als ${named('number')} Zeichen`,
            max_bytes: ({ named }: Parameters<MessageFunction>[0]) =>
                `Höchste Bytes überschreiten (${named('number')})`,
            less_than_characters: ({ named }: Parameters<MessageFunction>[0]) =>
                `Weniger als${named('number')} Zeichen`,
            must_be_characters: ({ named }: Parameters<MessageFunction>[0]) =>
                `Muss sein ${named('number')} Zeichen`,
            greater_than: ({ named }: Parameters<MessageFunction>[0]) =>
                `Größer als ${named('number')}`,
            less_than: ({ named }: Parameters<MessageFunction>[0]) =>
                `Weniger als ${named('number')}`,
            up_to_2_decimal_places: 'Bis zu 2 Dezimalstellen',
            retain_up:
                'Geben Sie bis zu 10 positive Ganzzahlen ein und behalten Sie bis zu 4 Dezimalstellen',
            retain_ups:
                'Geben Sie bis zu 11 positive Ganzzahlen ein und behalten Sie bis zu 4 Dezimalstellen',
            input_number: 'Bitte geben Sie die Nummer ein',
            only_number_a: 'Es können nur Buchstaben oder Zahlen eingegeben werden',
            translate: 'Nur Buchstaben',
            positive_integer: 'Geben Sie bis zu 10 positive Ganzzahlen ein',
            at_least_one: 'Füllen Sie mindestens eine aus',
        },
        tip: {
            incomplete_information: 'Unvollständige Informationen',
        },
    },
    import: {
        template_file: 'Template-Datei',
        browse: 'Durchsuchen',
        download_template: 'Vorlage herunterladen',
        no_file_selected: 'Keine Datei ausgewählt',
        incorrect_file_type: 'Falscher Dateityp',
        exceed: 'Weiter gehen',
        upload_success: 'Hochladen erfolgreich',
        upload_fail: 'Hochladen fehlgeschlagen',
        again_upload:
            'Es können nur Bilddateien im jpg, png, bmp oder gif Format hochgeladen werden',
        attachmentUpload: 'Anhang hochladen',
        otherAttachmentUpload: 'Sonstige Anhänge hochladen',
        supportedFormats: 'Unterstützte Formate',
        preview: 'Vorschau',
    },
    export: {
        noDataToExport: 'Keine Daten zum Exportieren',
    },
    select: {
        all: 'Alle',
    },
    checkbox: {
        select_all: 'Alles auswählen',
    },
    dialog: {
        error_message: 'Fehlermeldung',
        one_year: 'Dokumente können maximal für ein Jahr exportiert werden.',
    },
    ymd: {
        day: 'Tag',
        month: 'Monat',
        year: 'Jahr',
        january: 'Jan',
        february: 'Feb',
        march: 'Mar',
        april: 'Apr',
        may: 'May',
        june: 'Jun',
        july: 'Jul',
        august: 'Aug',
        september: 'Sep',
        october: 'Oct',
        november: 'Nov',
        december: 'Dec',
    },
};
